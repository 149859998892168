import styled from "styled-components";

export const CloseIconWrapper = styled.div`
  // width: 30px;
  // height: 30px;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  // &:hover{
  //   background-color: #cccccc;
  //   border-radius: 50%;
  //   img{
  //       filter: invert(0.5);
  //   }
  // }
  img{
    margin:0
  }
`