import styled from "styled-components";
import { Collapse } from "antd";

const { Panel: AntdPanel } = Collapse;

export const Panel = styled(AntdPanel)`
  box-sizing: border-box;
  max-width: 582px;
  height: 82px;
  padding: 26px 39px;
  border-bottom: 1px solid #000000 !important;
  &:last-child {
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-bottom: none !important;
  }
  &:first-child {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .ant-collapse-header {
    padding: 0px !important;
    span {
      display: block;
      height: fit-content;
      font-family: "Inter Regular";
      max-height: 46px;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
    }
  }
  .ant-collapse-header .ant-collapse-arrow {
    margin-top: 0;
    margin-right: 0;
    border-radius: 5px;
    width: 28px;
    height: 28px;
    right: 0px !important;

    @media (max-width: 320px){
      width: 20px;
      height: 20px
    }
  }

  &:hover {
    background-color: #dbedff;
    .ant-collapse-header {
      span {
        font-family: "Inter Medium";
        font-weight: 700;
      }
    }
  }

  @media (max-width: 991px){
    padding: 27px 26px 0px 33px;
  }

  @media (max-width: 453px){
    padding: 21px 23px 13px 27px;
    height: 60px;
    .ant-collapse-header {
        span {
          font-size: 14px;
          line-height: 21px;
        }
      }
  }
`;
