import React from "react";
import { Col, Row } from "antd";
import { FinalCalculationContainer } from "../styled-components/FinalCalculationContainer";
import { FinalCalculationText } from "../styled-components/FinalCalculationText";
import { MoneyTransition } from "./MoneyTransition";

type Props = {
  monthly: number;
  yearly: number;
};

export const FinalCalculation = React.memo(({ monthly, yearly }: Props) => {
  return (
    <Row className="bluebox-finalcalculation">
      <Col span={24}>
        <FinalCalculationContainer>
          <Row>
            <MoneyTransition value={monthly} />
            <FinalCalculationText> /month</FinalCalculationText>
          </Row>
          <Row>
            <MoneyTransition value={yearly} />
            <FinalCalculationText> /year</FinalCalculationText>
          </Row>
        </FinalCalculationContainer>
      </Col>
    </Row>
  );
});
