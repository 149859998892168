import styled from "styled-components";

export const Footer = styled.div`
height: 90px;
display: flex;
justify-content: center;
align-items: center;
background: #141414;
font-family: "Inter Regular";
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
  `;
