import React from "react";
import { FocusCard } from "../styled-components/FocusCard";
import { CalculationTitle } from "../styled-components/CalculationTitle";
import { Row } from "antd";
import { MoneyTransition } from "./MoneyTransition";
import { FinalCalculationText } from "../styled-components/FinalCalculationText";

type Props = {
  forceReset: boolean;
  impacts: string;
  extraPerMonth: number;
  extraPerYear: number;
  textPerMonth?: string;
  textPerYear?: string;
  firstLabel?: string;
};

export const SectionCalculations = ({
  forceReset,
  impacts,
  extraPerMonth,
  extraPerYear,
  textPerMonth,
  textPerYear,
  firstLabel,
}: Props) => {
  return (
    <FocusCard
      className="section-calculation"
    >
      <CalculationTitle>You will earn</CalculationTitle>
      <Row className="section-per-month">
        <MoneyTransition value={extraPerMonth || 0} />
        <FinalCalculationText>/{firstLabel}</FinalCalculationText>
      </Row>
      <Row className="section-per-year">
        <MoneyTransition value={extraPerYear || 0} />
        <FinalCalculationText>/year</FinalCalculationText>
      </Row>
    </FocusCard>
  );
};
