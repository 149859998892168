import styled from "styled-components";
import { Button } from "antd";

export const EmailModalSubmitButton = styled(Button)`
  background: #3399FF;
  box-sizing: border-box;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  width:151px;
  height:50px;
  font-family: "Inter Regular";
  font-size: 16px;
  line-height: 20px;
  &:hover, :focus{
    background: #121212;
    color:white;
  }
`;
