import {Dropdown, Menu} from "antd";
import React , {useState} from "react";
import ArrowUp from "../images/arrow-up.svg";
import ArrowDown from "../images/arrow-down.svg";
import {DropdownMenu} from "../styled-components/DropdownMenu"
import {Icon} from "../styled-components/Icon";
import {TimeDropdownMenu} from "../styled-components/TimeDropdownMenu";

type Props = {
  label: string;
  onChange: (value: string) => void;
};

const labels = ['/month', '/year'];

export const TimeDropdown = ({label, onChange}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Dropdown
      overlay={(
        <DropdownMenu selectedKeys={[label]}>
          {labels.map(x => (
            <TimeDropdownMenu key={x} onClick={() => onChange(x)}>
              {x}
            </TimeDropdownMenu>
          ))}
        </DropdownMenu>
      )}
      onVisibleChange={()=>setOpen(!open)}
      trigger={['click']}
    >
      <div>
        <span >{label || 'month'}</span>&nbsp;
        <Icon src={open ? ArrowDown:ArrowUp} width={16} height={16} style={{marginTop: 0, opacity: 0.6}} />
      </div>
    </Dropdown>
  );
}