import styled from "styled-components";
import { Layout as AntdLayout } from "antd";

export const HeaderLayout = styled(AntdLayout)<{openDrawer: boolean}>`
  background-color: #ffffff;
  padding: 0 50px;
  box-shadow: ${props=>props.openDrawer ? "" :"0px 3px 10px rgba(0, 0, 0, 0.1)"};
  height: 100px;
  margin-bottom: 77px;
  position: sticky;
  top: 0;
  overflow: visible;
  z-index: 9;

  @media only screen and (max-width: 991px) {
    margin-bottom: 44px;
    height: 66px;
    justify-content:center;
    padding-bottom: 10px;
    padding-top: 10px;
    a > img {
      width: 150px;
      height: 45px;
    }
    .menu-icon-wrapper {
      display:flex;
      padding-right: 18px;
      padding-bottom:2px; 
      img {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 479px) {
    padding: 20px 10px 10px;
    // height: 100px;
    margin-bottom: 34px;
    .menu-icon-wrapper {
      padding-right: 18px; 
      // padding-bottom: 18px; 
      
    }
  }
`;
