import styled from "styled-components";
import Text from "antd/es/typography/Text";

type ContainerType = {
  checked?: boolean; ///Passing Optional Props
};
export const SectionToggleCheckbox = styled(Text)<ContainerType>`
  font-family: "Inter Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  opacity: ${(props: ContainerType) =>
    props.checked? "1" : "0.6"};
  color: white !important;

  @media (max-width: 991px) and (min-width: 631px){
    font-size: 14px;
    line-height: 21px;
  }

  @media (max-width: 767px){
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 453px){
    font-size: 14px;
    line-height: 21px;
  }
`;
