import { Icon } from "../styled-components/Icon";
import CloseIcon from "../images/Close-icon2.svg";
import { Modal } from "../styled-components/Modal";
import { CloseIconWrapper } from "../styled-components/CloseIconWrapper";
import { EmailModalTitle } from "../styled-components/EmailModalTitle";
import styled from "styled-components";

type Props = {
  visible: boolean;
  onCancel: () => void;
};

export const ConfirmationModal = ({ visible, onCancel }: Props) => {
  return (
    <ModalWrapper
      centered
      visible={visible}
      onOk={onCancel}
      destroyOnClose
      closeIcon={
        <CloseIconWrapper>
          <Icon src={CloseIcon} width={20} height={20} />
        </CloseIconWrapper>
      }
      onCancel={onCancel}
      footer={null}
    >
      <BodyWrapper>
        <EmailModalTitle>Thank you! </EmailModalTitle>
        <TextWrapper>
          You will receive a copy of your report shortly. Please check your
          inbox 📫.
        </TextWrapper>
      </BodyWrapper>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(Modal)`
    .ant-modal-content, .ant-modal-body{
        height: 100%;
    }
    .ant-modal-content button{
        top: -20px !important;
    }

`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const TextWrapper = styled.div`
  margin-top: 40px;
  max-width: 400px;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
`;
