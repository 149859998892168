import {Collapse as AntdCollapse} from "antd";
import styled from "styled-components";

export const Collapse = styled(AntdCollapse)`
  background-color: #ffffff !important;
  border: 3px solid #000000;
  border-radius: 15px;
  .ant-collapse-item-active{
    height:fit-content !important;
    .ant-collapse-header{
      span{
        font-family: "Inter Medium";
        font-weight: 700;
      }
    }
    .ant-collapse-content-active{
      margin-top: 48px;
      .ant-collapse-content-box{
        padding:0px
      }
      @media (max-width: 453px){
        margin-top: 19px
      }
    }
    &:hover{
      background-color: white
    }
    
  }
  .ant-collapse-content-inactive{
    display:none;
  }
`;