import React, { useState } from "react";
import { Form, Input, Row, Col } from "antd";
import { EmailModalSubmitButton } from "../styled-components/EmailModalSubmitButton";
import { EmailModalInputLabel } from "../styled-components/EmailModalInputLabel";
import { Icon } from "../styled-components/Icon";
import Close from "../images/close.svg";
import CloseIcon from "../images/Close-icon2.svg";
import { getClient } from "../request";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { failedToSendEmail, successfullySentEmail } from "../constants";
import { Modal } from "../styled-components/Modal";
import { EmailModalTitle } from "../styled-components/EmailModalTitle";
import ArrowRight from "../images/arrowright.svg";
import { CloseIconWrapper } from "../styled-components/CloseIconWrapper";
import styled from "styled-components";

type Props = {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  onTrack: () => void;
  onSubmit: (values: any, isPrint: Boolean) => void;
};

export const EmailModal = ({
  visible,
  onOk,
  onCancel,
  onTrack,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ id?: string }>();

  const handleSubmit = async (values: any): Promise<void> => {
    try {
      setLoading(true);
      // await getClient().post(`/report/${params.id}/email`, values);
      // await Swal.fire({
      //   title: successfullySentEmail,
      //   confirmButtonColor: "#3399FF",
      // });
      onSubmit(values, false);
      onOk();
      onTrack();
    } catch (e) {
      await Swal.fire({
        title: failedToSendEmail,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={onOk}
      destroyOnClose
      closeIcon={
        <CloseIconWrapper>
          <Icon src={CloseIcon} width={20} height={20} />
        </CloseIconWrapper>
      }
      onCancel={onCancel}
      footer={null}
    >
      <EmailModalTitle>Email Report</EmailModalTitle>
      <Form
        name="email_report"
        layout="vertical"
        size="large"
        className="form-body"
        style={{ marginTop: 40 }}
        onFinish={handleSubmit}
      >
        <NameContainer>
          <Form.Item
            label="First name"
            name="first_name"
            className="form-input"
            rules={[
              { required: true, message: "Please enter your first name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last name"
            className="form-input"
            name="last_name"
            rules={[{ required: true, message: "Please enter your last name" }]}
            style={{ marginRight: "0px!important" }}
          >
            <Input />
          </Form.Item>
        </NameContainer>
        <Form.Item
          label="Club name"
          name="name"
          rules={[{ message: "Please enter your club name" }]}
          style={{ marginBottom: 17, width: 750, maxWidth: "100%" }}
        >
          <Input />
        </Form.Item>
        <EmailInputContainer
          label="Email address"
          name="email"
          rules={[{ required: true, message: "Please enter your email" }]}
          style={{ marginBottom: 25, width: 750, maxWidth: "100%" }}
        >
          <Input type="email" />
        </EmailInputContainer>
        <BottomContainer>
          <EmailModalSubmitButton loading={loading} htmlType="submit">
            Send
          </EmailModalSubmitButton>
        </BottomContainer>
      </Form>
    </Modal>
  );
};

const NameContainer = styled(Row)`
  flex-flow: nowrap;

  .form-input {
    width: 365px;   
    border: "none";
    margin-bottom: 17px;
    &:hover {
      input {
        border: none !important;
      }
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
    @media (max-width: 575px) {
      width: 100%;
      margin-bottom: 17px !important;

    }
  }
  @media (max-width: 575px) {
    flex-direction: column;
    width: 100%;
    margin-right: 0px;
  }
`;

const BottomContainer = styled(Form.Item)`
  @media (max-width: 575px) {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }
`;

const EmailInputContainer = styled(Form.Item)`
  @media (max-width: 575px) {
    margin-bottom: 30px !important;
  }
`;
