import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const CalculationTitle = styled(Text)`
  font-family: "Inter SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  @media (max-width: 453px){
    font-size: 14px;
    line-height: 21px;
  }
`;
