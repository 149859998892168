import React from "react";
import {BrowserRouter, Route, Switch,} from "react-router-dom";
import {Calculator} from "./Calculator";
import {FontStyles} from "./styled-components/FontStyles";

export const App = () => {
  return (
    <BrowserRouter>
        <FontStyles />
        <Switch>
        <Route path={["/report/:id", "/"]}>
          <Calculator/>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
