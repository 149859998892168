import styled from "styled-components";
import {Layout as AntdLayout} from "antd";

export const Layout = styled(AntdLayout)`

  background-color: #ffffff;
  padding: 0 102px 0 102px;
  @media only screen and (max-width: 1279px) {
    width: 100%;
    padding: 0 15px;
  }
  @media (max-width: 453px){
    padding: 0 10px;
  }
`;
