import styled from "styled-components";
import { Col } from "antd";

export const Summary = styled(Col)`
  max-width: 388px;
  width: 100%;
  background: #3399ff;
  border-radius: 10px;
  padding-bottom: 52px;
  padding: 48px 0px 48px 52px;
  height: 740px;
  position: sticky;
  top: 60px;

  @media (max-width: 1039px) {
    max-width: 384px;
    width: 100%;
  }

  @media (max-width: 1020px) {
    max-width: 373px;
    width: 100%;
  }

  @media (max-width: 991px) {
    max-width: 289px;
    width: 100%;
    padding: 44px 30px 49px;
    height: 715px;
  }

  @media (max-width: 767px) {
    max-width: 434px;
    width: 100%;
    padding: 48px 69px 52px; 
    height: 740px;
  }

  @media (max-width: 453px) {
    padding: 44px 39px 49px 36px;
    height: 694px;
  }

  @media (max-width: 340px) {
    max-width: 300px;
    width: 100%;
    height: 715px;
  }

`;
