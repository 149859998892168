import styled from "styled-components";

export const FormHelper = styled.span`
  font-style: normal;
  font-weight: 500;
  font-family: "Inter Medium";
  font-size: 12px;
  margin-top: 4px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
  max-width: 90%;
  @media (max-width: 320px){
    max-width: 100%;
  }
  `
  ;