import styled from "styled-components";

export const FinalCalculationContainer = styled.div`
  // background: #ffffff;
  background: rgba(255,255,255,.2);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px 0;
  text-align: center;
  // margin: 0 15px;
  color:white;

  div:first-child{
    margin-bottom: 5px;
    @media (max-width: 991px) and (max-width: 631px){
      margin-bottom: 8px;

    }
    @media (max-width: 453px){
      margin-bottom: 8px;
    }
  }
  div{
    display:flex;
    justify-content:center;
    align-content:center;
    span{
      font-size:36px;
      line-height: 54px;
      font-weight:700;

      @media (max-width: 991px){
        font-size: 28px;
        line-height: 42px;
      }

      @media (max-width: 767px){
        font-size: 36px;
        line-height: 54px;
      }

      @media (max-width: 453px){
        font-size: 28px;
        line-height: 42px;
      }
    }
    span:last-child{
      font-size:18px;
      margin-left: 5px;
    }
  };
  div:first-child{
    margin-bottom: 5px
    @media (max-width: 453px){
      margin-bottom: 0px;
    }
  }
  
`;