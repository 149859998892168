import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const FinalCalculationText = styled(Text)`
  display: block;
  font-style: normal;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-left: 1px;
  color: #121212;
`;