import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const PanelHeader = styled(Text)`
font-family: "Inter SemiBold";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
height:60px;

`;
