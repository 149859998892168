import React from 'react';
import {SectionToggleTitle} from "../styled-components/SectionToggleTitle";
import {Col, Row} from "antd";
import {sections} from "../constants";
import {SectionToggleCheckbox} from "../styled-components/SectionToggleCheckbox";
import {SectionToggleContainer} from "../styled-components/SectionToggleContainer";
import CheckboxChecked from "../images/checkbox_checked.svg";
import CheckboxUnchecked from "../images/uncheckedbox.svg";
import CheckedBoxIcon from "../images/components/CheckedBox"
import UncheckBoxIcon from "../images/components/UncheckedBox";
import {Icon} from "../styled-components/Icon";

type Props = {
  onChange: (section: string, status: boolean) => void;
  activeSections: string[];
};

export const SectionToggle = React.memo(({ activeSections, onChange }: Props) => {

  const handleChange = ({name, value}: { name: string, value: boolean}) => {
    onChange(name, value);
  };

  return (
    <SectionToggleContainer>
      <Row >
          <SectionToggleTitle>Add It All Up... </SectionToggleTitle>
      </Row>
      <Row >
        {/* <Col> */}
          {Object.keys(sections).map(section => {
            return (
              <React.Fragment key={section}>
                <Row className="bluebox-checkboxes" gutter={[0,0]}>
                  <Col style={{marginRight:12}}>
                    {activeSections.includes(section) ?
                    <CheckedBoxIcon style={{width:16, height:16, marginTop: 3}} onClick={() => {
                      handleChange({
                          name: section,
                          value: !activeSections.includes(section)
                      })
                    }}/>
                    :
                    <UncheckBoxIcon style={{width:16, height:16, opacity: 0.6, marginTop: 3}} className="unchecked" onClick={() => {
                      handleChange({
                          name: section,
                          value: !activeSections.includes(section)
                      })
                    }}/>
                    }
                  </Col>
                  <Col span={21}>
                    <SectionToggleCheckbox checked={activeSections.includes(section)}>{sections[section].name}</SectionToggleCheckbox>
                  </Col>
                </Row>
              </React.Fragment>
            )
          })}
        {/* </Col> */}
      </Row>
    </SectionToggleContainer>
  )
});