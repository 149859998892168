import {Money} from "../styled-components/Money";
import {useCountUp} from "react-countup";
import React, {useEffect} from "react";
import {MoneyCurrency} from "../styled-components/MoneyCurrency";


const format = (value: number): string => {
  return value.toLocaleString();
};

const transform = (value: number): number => {
  return Number.isNaN(value) ? 0 : value;
};

const countUpDuration = 0.4;

type Props = {
  value: number;
};

export const MoneyTransition = React.memo(({value}: Props) => {

  const { countUp, update } = useCountUp({
    end: transform(value),
    startOnMount: false,
    formattingFn: format,
    duration: countUpDuration
  });

  useEffect(() => {
    setTimeout(() => {
      update(value);
    });
  }, [update, value]);
  return <>
    <MoneyCurrency>$</MoneyCurrency><Money>{countUp}</Money>
  </>

});