import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const EmailModalTitle = styled(Text)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #141414;
  display: flex;
  justify-content: center;
  font-family: "Inter Bold";
  @media (max-width: 575px) {
    margin: 17px 0px 3px 0px;
  }
`;
