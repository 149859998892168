import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const CallToActionDescription = styled(Text)`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-family: "Inter Regular";
  color: #000000;
  max-width: 680px;
  margin-left: 0;
  @media (max-width:991px){
    font-size: 16px !important;
    line-height: 24px !important;
  }

  @media (max-width:453px){
    font-size: 14px !important;
    line-height: 21px !important;
  }
`;
