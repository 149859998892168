import axios from 'axios';

export const getClient = () => {
    const baseURL = (() => {
        switch (window.location.hostname) {
            case 'ec2-3-85-33-51.compute-1.amazonaws.com':
                return 'http://ec2-3-85-33-51.compute-1.amazonaws.com/api/';
            case 'simulators.ocamsclub.com':
                return 'https://simulators.ocamsclub.com/api/';
            default:
                return 'http://localhost:4000/api/';
        }
    })();
    return axios.create({
        baseURL,
    });
}