import styled from "styled-components";
import {Header as AntdHeader} from "antd/es/layout/layout";

export const Header = styled(AntdHeader)`
  background-color: transparent !important;
  padding: 0;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  max-width:1440px;
  width:100%; 
  margin: 0 auto 57px;
  overflow:visible;
  z-index:2;
  .logo{
    width: 200px;
     height: 60px
  }

  @media (max-width:991px){
    height: unset;
    margin: 0 auto;
    .logo{
      width: 150px;
       height: 45px
    }
  }
  @media (max-width:767px){
      padding-left:10px;
      .logo{
        width: 148px;
      }
  }
`;
