import { createGlobalStyle } from "styled-components";

// @ts-ignore
import InterRegularTtf from "../fonts/inter/Inter-Regular.ttf";
// @ts-ignore
import InterBodTtf from "../fonts/inter/Inter-Bold.ttf";
// @ts-ignore
import InterMediumTtf from "../fonts/inter/Inter-Medium.ttf";
// @ts-ignore
import InterSemiBold from "../fonts/inter/Inter-SemiBold.ttf";
// @ts-ignore
import InterExtraBold from "../fonts/inter/Inter-ExtraBold.ttf";


export const FontStyles = createGlobalStyle`
  @font-face {
    font-family: "Inter Regular";
    src: url(${InterRegularTtf});
    src: local("Inter Regular"), local("Inter-Regular"),
    format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Inter Medium";
    src: url(${InterMediumTtf});
    src: local("Inter Medium"), local("Inter-Medium"),
    format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Inter Bold";
    src: url(${InterBodTtf});
    src: local("Inter Bold"), local("Inter-Bold"),
    format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Inter";
    src: local("Inter Regular"),
    src: url(${InterRegularTtf});
    format("truetype");
  }
  @font-face {
    font-family: "Inter ExtraBold";
    src: local("Inter ExtraBold"),
    url(${InterExtraBold})
    format("truetype");
  }
  @font-face {
    font-family: "Inter SemiBold";
    src: local("Inter SemiBold"),
    url(${InterSemiBold})
    format("truetype");
  }

  * {
    font-family: 'Inter Regular';
  }
`;
