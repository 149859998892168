import styled from "styled-components";
import {Menu} from "antd";

export const DropdownMenu = styled(Menu)`
    width: 102px;
    right: 21px;
    &li{
        height: 25px;
    }
`;
