import styled from "styled-components";

export const SectionToggleContainer = styled.div`
  margin-bottom: 41px;
  > div:first-child {
    margin-bottom: 24px;
  }
  padding-left: 2px;
  @media (max-width: 992px) {
    padding-left: 6px;
    margin-bottom: 55px;
    > div:first-child {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 991px) {
    padding-left: 0px;
    margin-bottom: 25px;
    > div:first-child {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 41px;
    > div:first-child {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 453px) {
    margin-bottom: 28px;
    > div:first-child {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 320px) {
    margin-bottom: 25px;
  }

  @media (max-width: 470px) {
    > div:last-child {
      padding-left: 0;
    }
  }
`;
