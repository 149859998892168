import {Section, State} from "./types";
import { TimeDropdown } from "./components/TimeDropdown";

export const ctaTitle = 'How Much Revenue Can You Make From Video?';

export const ctaDescriptions = [
  'With Ocams, you have the unique ability to give video to everyone every time they play, without them having to request it. This means you could easily make video a standard amenity at your club, which allows you to raise fees  across the board.',
  'Use the simulator below to see how much extra revenue you could generate from video. Enjoy!',
];

export const extraRevenuePerMonthText = 'extra revenue from video per month';
export const extraRevenuePerYearText = 'extra revenue from video per year';

export const extraRevenuePerSession = 'extra revenue from video per session';
export const extraRevenuePerTournament = 'extra revenue from video per tournament';

export const sections: { [key: string]: Section } = {
  raise_membership_fee: {
    name: 'Raise Membership Fee',
    impacts: 'membership fees',
    inputs: [
      {
        name: 'How many members do you have?',
        suffix: 'members',
        key: 'members',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'What is the current membership fee?',
        prefix: '$',
        suffix: (label, onChange) => (
          <>
            <TimeDropdown label={label} onChange={onChange} />
          </>
        ),
        suffixKey: 'feeTime',
        key: 'fee',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'If you increase membership fee by',
        prefix: '$',
        suffix: (label, onChange) => (
          <>
            <TimeDropdown label={label} onChange={onChange} />
          </>
        ),
        suffixKey: 'increaseTime',
        key: 'increase',
        type: 'text',
        placeholder: '0',
        error:'',
      }
    ],
  },
  raise_court_time_fee: {
    name: 'Raise Court Time Fee',
    impacts: 'court time fees',
    inputs: [
      {
        name: 'How many courts does your club have?',
        suffix: 'courts',
        key: 'courts',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'How many hours of court time do you sell each day?',
        suffix: 'hours/day',
        key: 'per_court_per_day',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'What is your court time fee?',
        suffix: '/hour',
        prefix: '$',
        key: 'per_hour',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'If you increase court time fee by',
        suffix: '/hour',
        prefix: '$',
        key: 'increase',
        type: 'text',
        placeholder: '0',
        error:'',
      },
    ],
  },
  raise_private_lesson_fee: {
    name: 'Raise Private Lesson Fee',
    impacts: 'private lesson fees',
    inputs: [
      {
        name: 'What is the private lesson fee at your club?',
        suffix: '/hour',
        prefix: '$',
        key: 'per_hour',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'How many hours of private lessons do you sell per day?',
        suffix: 'hours/day',
        key: 'per_day',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'If you increase private lesson fee by',
        suffix: '/hour',
        prefix: '$',
        key: 'increase',
        type: 'text',
        placeholder: '0',
        error:'',
      },
    ],
  },
  raise_junior_program_fee: {
    name: 'Raise Junior Program Fee',
    impacts: 'junior program fees',
    inputs: [
      {
        name: 'How many junior program sessions* do you run each year?',
        suffix: 'sessions/year',
        helper: '*A session is a semester, a quarter, a summer, or however long your junior program is.',
        key: 'sessions',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'How many students are enrolled in each session?',
        suffix: 'students/session',
        key: 'students',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'What is the junior program fee per session?',
        suffix: '/session',
        prefix: '$',
        key: 'per_session',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'If you increase junior program fee by',
        suffix: '/student',
        prefix: '$',
        key: 'increase',
        type: 'text',
        placeholder: '0',
        error:'',
      },
    ],
  },
  sell_videos_and_live_stream_tickets_at_tournaments: {
    name: 'Sell Videos & Livestream Tickets',
    impacts: 'tournament fees',
    inputs: [
      {
        name: 'How many tournaments do you run a year?',
        suffix: 'tournaments/year',
        key: 'tournaments',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'What is the entry fee per player?',
        suffix: '/player',
        prefix: '$',
        key: 'per_player',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'How many players enter each tournament?',
        suffix: 'players',
        key: 'player',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'How many videos could you sell in each tournament?',
        suffix: 'videos',
        key: 'videos',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'How much would you sell a video for?',
        suffix: '/video',
        prefix: '$',
        key: 'per_video',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'How many livestream tickets* could you sell in each tournament?',
        suffix: 'tickets',
        helper: '*A livestream ticket is good for one day.',
        key: 'tickets',
        type: 'text',
        placeholder: '0',
        error:'',
      },
      {
        name: 'How much would you sell a livestream ticket for?',
        suffix: '/ticket',
        prefix: '$',
        key: 'per_ticket',
        type: 'text',
        placeholder: '0',
        error:'',
      },
    ],
  },
};

export const successfullySentEmail = 'We\'ve sent you an email with a link to your report!';
export const failedToSendEmail = 'Sorry, we failed to send an email to you with your report link, please check your name and email and re-submit.';

export const defaultActiveSections = {
  raise_membership_fee: false,
  raise_court_time_fee: false,
  raise_private_lesson_fee: false,
  raise_junior_program_fee: false,
  sell_videos_and_live_stream_tickets_at_tournaments: false,
};

export const defaultActiveCalculatedSections = {
  raise_membership_fee: true,
  raise_court_time_fee: true,
  raise_private_lesson_fee: true,
  raise_junior_program_fee: true,
  sell_videos_and_live_stream_tickets_at_tournaments: true,
};

export const defaultSectionInputs = {
  raise_membership_fee: {
    feeTime: '/month',
    increaseTime: '/month',
  },
  raise_court_time_fee: {},
  raise_private_lesson_fee: {},
  raise_junior_program_fee: {},
  sell_videos_and_live_stream_tickets_at_tournaments: {},
};

export const initialState: State = {
  activeSections: defaultActiveSections,
  activeCalculatedSections: defaultActiveCalculatedSections,
  sections: defaultSectionInputs,
  forceReset: false,
};

export const defaultTotals = {
  sections: {
    raise_membership_fee: {
      extraPerMonth: 0,
      extraPerYear: 0,
      totalPerMonth: 0,
      totalPerYear: 0,
    },
    raise_court_time_fee: {
      extraPerMonth: 0,
      extraPerYear: 0,
      totalPerMonth: 0,
      totalPerYear: 0,
    },
    raise_private_lesson_fee: {
      extraPerMonth: 0,
      extraPerYear: 0,
      totalPerMonth: 0,
      totalPerYear: 0,
    },
    raise_junior_program_fee: {
      extraPerMonth: 0,
      extraPerYear: 0,
      totalPerMonth: 0,
      totalPerYear: 0,
    },
    sell_videos_and_live_stream_tickets_at_tournaments: {
      extraPerMonth: 0,
      extraPerYear: 0,
      totalPerMonth: 0,
      totalPerYear: 0,
    }
  },
  totals: {
    per_month: 0,
    per_year: 0,
  }
};
