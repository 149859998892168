import React, { useEffect, useState } from "react";
import { Section } from "../types";
import { Col, Form, Input, Row } from "antd";
import { InputLabel } from "../styled-components/InputLabel";
import { FormHelper } from "../styled-components/FormHelper";

type Props = {
  forceReset: boolean;
  section: string;
  inputs: Section["inputs"];
  onChange: (values: { [key: string]: any }) => void;
  values: any;
};

export const SectionInputs = React.memo(
  ({ forceReset, section, inputs, onChange, values }: Props) => {
    const [form] = Form.useForm();
    const [errors , setErrors] = useState<any>({})
    const handleChange = (changedValues: any) => {
      // Make sure the input value is always a number
      const [key] = Object.keys(changedValues);
      let value;
      if (changedValues[key] && !isNaN(changedValues[key])) {
        value = parseFloat(changedValues[key]);
      } else value = changedValues[key];
      // If value is not 0, fire onChange
      if (value !== 0) {
        onChange({
          ...values,
          [key]: value,
        });
        // Set the parsed value
        form.setFieldsValue({
          [key]: value.toString(10),
        });
      } else {
        // If the value is 0, set it to empty again
        form.setFieldsValue({
          [key]: "",
        });
      }
    };

    const handleTimeChange = (key: string) => {
      return (value: string) => {
        onChange({
          ...values,
          [key]: value,
        });
      };
    };

    useEffect(() => {
      if (forceReset) {
        form.resetFields();
      }
    }, [form, values, forceReset]);

    const initialValues: { [key: string]: string } = {};
    Object.keys(values).forEach((key) => {
      initialValues[key] = (values[key] || "").toString();
    });

    return (
      <>
        <Form form={form} onValuesChange={handleChange} initialValues={values}>
          {inputs.map((input) => {
            const id = `${section}-${input.key}`;
            return (
              <div className="question-wrapper">
                <Row
                  key={id}
                  justify="space-between"
                  className="label-container"
                >
                  <InputLabel>{input.name}</InputLabel>
                  {input.helper && <FormHelper>{input.helper}</FormHelper>}
                </Row>
                <Row>
                  <div
                    onClick={() => {
                      const input: HTMLInputElement | null =
                        document.getElementById(id) as HTMLInputElement;
                      if (input) {
                        input.select();
                      }
                    }}
                    className="section-inputs"
                  >
                    <Form.Item
                      name={input.key}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let newErrors = { ...errors}
                            if (!value) {
                              input.error = "";
                              newErrors[input.key] = "";
                              // return Promise.resolve();
                            }
                            if (
                              !value.toString().includes(".") &&
                              value % 1 === 0
                            ) {
                              newErrors[input.key] = "";
                              // return Promise.resolve();
                            } else {
                              newErrors[input.key] = "Please enter a number";
                            }
                            setErrors(newErrors)
                            // return Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder={input.placeholder}
                        id={id}
                        className={
                          input.prefix ? "ocams-prefix-offset with-prefix" : ""
                        }
                        type="text"
                        // min={0}
                        // step={1}
                        prefix={input.prefix}
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) =>
                          (e.target.placeholder = `${input.placeholder}`)
                        }
                      />
                    </Form.Item>
                    <div style={{ marginLeft: "5px" }}>
                      {input.suffix &&
                        (typeof input.suffix === "function" &&
                        input.suffixKey ? (
                          input.suffix(
                            values[input.suffixKey],
                            handleTimeChange(input.suffixKey)
                          )
                        ) : (
                          <div className="ant-dropdown-trigger">
                            {input.suffix}
                          </div>
                        ))}
                    </div>
                  </div>
                </Row>
                {errors[input.key] && (
                    <div className="input-error">{errors[input.key]}</div>
                  )}
              </div>
            );
          })}
        </Form>
      </>
    );
  }
);
