import styled from "styled-components";
import { Button } from "antd";

export const ActionButton = styled(Button)`
  width: 200px;
  height: 60px;
  border: none;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  background: transparent;
  color: #121212 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  transition:none;
  
  @media (max-width: 991px){
    font-size: 16px;
  }
`;
