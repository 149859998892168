import styled from "styled-components";

export const CallToActionContent = styled.div`
  text-align: center;
  @media (max-width: 767px){
    max-width: 610px;
  }
  @media (max-width: 453px){
    text-align: left
  }
`
