import { Modal as OriginalModal } from "antd";
import styled from "styled-components";

export const Modal = styled(OriginalModal)`
  font-family: 'Inter Regular', sans-serif;
  width: 890px !important;
  height: 565px;
  transform-origin: 604.5px 282.5px;
  padding: 58px 70px 61px;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;

  .ant-modal-content {
    transform-origin: none;
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
  }
  .ant-modal-close {
    top: -40px;
    right: -50px;
    @media (max-width: 575px){
      right: -20px;
    }
  }

  .ant-modal-close-x{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center
  }
  .ant-modal-body {
    padding: 0;
    .ant-form-item-label >label {
      height: 32px;
      font-size:16px;
      &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
        display:none
      }
      @media (max-width: 575px){
        height: 24px;
      }
    }
  }

  .ant-form-item-label {
    padding: 0;
  }

  input {
    height: 60px;
    font-family: "Inter Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    padding-left: 18px;
    padding-right: 18px;
    background: rgba(18, 18, 18, 0.05);
border-radius: 5px;
border:none !important;
    &::placeholder {
      color: #121212;
    };
    &:hover,
    :focus{
      transition:none;
      box-shadow: 0px 2px 6px rgba(18, 18, 18, 0.7);
    }
  }
  
  @media (max-width:575px){
      padding: 39px;
      height: 664px;
  }
`;
