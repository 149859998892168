import React from "react";

const CheckedBoxIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="1"
        fill="white"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M11.8333 5.79163L6.61979 11.2083L4.25 8.74617"
        stroke="#3399FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CheckedBoxIcon;
