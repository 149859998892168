import React, {useCallback, useEffect, useRef, useMemo, useState} from 'react';
import 'antd/dist/antd.css';
import {useHistory, useParams} from "react-router-dom";
import {Col, Row} from "antd";
import debounce from 'debounce';
import ArrowDownIcon from "./images/components/ArrowDown.js"
import ArrowUpIcon from "./images/components/ArrowUp";
import Email from './images/email1.svg';
import EmailIcon from './images/components/EmailIcon';
import EmailIconHover from './images/components/EmailIconHover';
import Swal from 'sweetalert2';
import rfdc from 'rfdc';
import {
  ctaDescriptions,
  ctaTitle,
  defaultActiveSections,
  defaultTotals,
  extraRevenuePerSession, extraRevenuePerTournament,
  initialState,
  sections
} from "./constants";
import {Content} from "antd/es/layout/layout";
import {CallToActionTitle} from "./styled-components/CallToActionTitle";
import {CallToActionDescription} from "./styled-components/CallToActionDescription";
import {Layout} from "./styled-components/Layout";
import {HeaderLayout} from "./styled-components/HeaderLayout";
import {Header} from "./styled-components/Header";
import {AppWrapper} from "./styled-components/AppWrapper";
import {FocusCard} from "./styled-components/FocusCard";
import {SectionCalculations} from "./components/SectionCalculations";
import {Icon} from "./styled-components/Icon";
import {Panel} from "./styled-components/Panel";
import {Collapse} from "./styled-components/Collapse";
import {PanelHeader} from "./styled-components/PanelHeader";
import {SectionInputs} from "./components/SectionInputs";
import {SectionToggle} from "./components/SectionToggle";
import {FinalCalculation} from "./components/FinalCalculation";
import {ActionButton} from "./styled-components/ActionButton";
import {getClient} from "./request";
import {State} from "./types";
import {Footer} from "./styled-components/Footer";
import {EmailModal} from "./components/EmailModal";
import {ConfirmationModal} from "./components/ConfirmationModal";
import {CallToActionContent} from "./styled-components/CallToActionContent";
import {ActionButtonBordered} from "./styled-components/ActionButtonBordered";
import {Summary} from './styled-components/Summary';
import OcamsLogo from "./images/animations/OcamsLogoAnimation.json";
import menuV2 from "./images/animations/menuV2.json";
import lottie, { AnimationItem } from "lottie-web";
import {useWindowSize} from "./components/hook/WindowSize";
import PrintIconO from "./images/components/PrintIconO";
import PrintIconHover from "./images/components/PrintIconHover";

interface PdfObject {
  name?: string,
  checked: Boolean,
  questions: any[],
  totals:{
    month: string,
    year: string,
  }
  [key: string]: any,
}

export const Calculator = () => {
  const [state, setState] = useState<State>(initialState);
  const [emailModalStatus, setEmailModalStatus] = useState<boolean>(false);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState<boolean>(false);
  const [showAudio, setShowAudio] = useState<boolean>(false);
  const params = useParams<{ id?: string; }>();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [logoAnimation, setLogoAnimation] = useState<AnimationItem>();
  const [menuAnimation, setMenuAnimation] = useState<AnimationItem>();
  const logoRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();

  useEffect(() => {
    if(logoRef.current){
      setLogoAnimation(lottie.loadAnimation({
        container: logoRef.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: OcamsLogo
      }))
    }
    lottie.setDirection(1)
    lottie.play()
    return () => {
      lottie.destroy();
    };
  }, []);

  useEffect(() => {
    if(menuRef.current){
      setMenuAnimation(lottie.loadAnimation({
        container: menuRef.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: menuV2
      }));
    }

    return () => {
      lottie.destroy();
    };
  }, []);

  useEffect(() => {
    setIsMobile(size.width < 992)
  }, [size]);

  useEffect(() => {
    // Start a report session as soon as some hits homepage
    if (!params.id) {
      (async () => {
        try {
          const {status, data} = await getClient().post('/session');
          if (status === 200) {
            history.push(`/report/${data.id}`);
          }
        } catch (e) {
          // await Swal.fire({
          //   icon: 'error',
          //   title: 'Unable to create your session. Please refresh to try again!'
          // });
        }
      })();
    }
  }, [params.id, history]);

  useEffect(() => {
    // Get the session data
    if (params.id) {
      (async () => {
        try {
          const {data, status} = await getClient().get(`/report/${params.id}`);
          if(status === 200 && typeof data._id !== 'undefined') {
            setState(prevState => ({
              ...prevState,
              ...data.data,
              forceReset: true,
            }));
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Invalid report link, you can generate a new report',
              confirmButtonText: `Ok`,
            });
            history.push('/');
          }
        } catch (e) {
          await Swal.fire({
            icon: 'error',
            title: 'Unable get your report. Please make sure your report link is correct'
          });
          history.push('/');
        }
      })();
    }
  }, [params.id, history]);

  const activeSections = useMemo(() => {
    // @ts-ignore
    return Object.keys(state.activeSections).filter((section: string) => state.activeSections[section]);
  }, [state]);

  const activeCalculatedSections = useMemo(() => {
    // @ts-ignore
    return Object.keys(state.activeCalculatedSections).filter((section: string) => state.activeCalculatedSections[section]);
  }, [state]);

  const handleSave = debounce(async (payload: State) => {
    await getClient().post(`/report/${params.id}`, payload);
  }, 1000);

  const handleTrack = async () => {
    await getClient().post(`/report/${params.id}/track`);
  };

  const handleAutoSave = (payload: State) => {
    setState(payload);
    handleSave(payload);
  };

  const handleSectionToggle = (sections: string[]) => {
    const activeSections = rfdc()(defaultActiveSections);
    for(const section of sections) {
      // @ts-ignore
      activeSections[section] = true;
    }
    const payload: State = {
      ...state,
      activeSections,
      forceReset: false,
    };
    handleAutoSave(payload);
  };

  const handleCalculatedSectionToggle = (section: string, status: boolean) => {
    const payload: State = {
      ...state,
      activeCalculatedSections: {
        ...state.activeCalculatedSections,
        [section]: status
      },
      forceReset: false,
    };
    handleAutoSave(payload);
  };

  const handleChange = (section: string, values: { [key: string]: any; }): void => {
    const payload: State = {
      ...state,
      sections: {
        ...state.sections,
        [section]: values
      },
      forceReset: false,
    };
    handleAutoSave(payload);
  };

  const checkEnabledSection = useCallback((section: string, value?: number): number => {
    return activeCalculatedSections.includes(section) && value ? value : 0;
  }, [activeCalculatedSections]);

  // All the calculations based on the input provided
  const calculations = useMemo(() => {
    const {
      raise_membership_fee,
      raise_court_time_fee,
      raise_private_lesson_fee,
      raise_junior_program_fee,
      sell_videos_and_live_stream_tickets_at_tournaments: sell_and_live
    } = state.sections;
    const totals = rfdc()(defaultTotals);
    // This section can probably be refactored for shorter names..
    if(raise_membership_fee.members && raise_membership_fee.increase && raise_membership_fee.fee && raise_membership_fee.increaseTime) {
      const increase = raise_membership_fee.increase / (raise_membership_fee.increaseTime === '/year' ? 12 : 1);
      totals.sections.raise_membership_fee.extraPerMonth = raise_membership_fee.members * increase;
      totals.sections.raise_membership_fee.extraPerYear = 12 * totals.sections.raise_membership_fee.extraPerMonth;
    }
    if(raise_court_time_fee.per_court_per_day && raise_court_time_fee.increase) {
      totals.sections.raise_court_time_fee.extraPerMonth = 30.5 * raise_court_time_fee.per_court_per_day * raise_court_time_fee.increase;
      totals.sections.raise_court_time_fee.extraPerYear = 12 * totals.sections.raise_court_time_fee.extraPerMonth;
    }
    if(raise_private_lesson_fee.per_day && raise_private_lesson_fee.increase) {
      totals.sections.raise_private_lesson_fee.extraPerMonth = 30.5 * raise_private_lesson_fee.per_day * raise_private_lesson_fee.increase;
      totals.sections.raise_private_lesson_fee.extraPerYear = 12 * totals.sections.raise_private_lesson_fee.extraPerMonth;
    }
    if(raise_junior_program_fee.students && raise_junior_program_fee.increase ) {
      totals.sections.raise_junior_program_fee.extraPerMonth = raise_junior_program_fee.students * raise_junior_program_fee.increase;
    }
    if(raise_junior_program_fee.sessions) {
      totals.sections.raise_junior_program_fee.extraPerYear = raise_junior_program_fee.sessions * totals.sections.raise_junior_program_fee.extraPerMonth;
    }
    let sellAndLiveExtraPerMonth = 0;
    if(sell_and_live.videos && sell_and_live.per_video) {
      sellAndLiveExtraPerMonth += sell_and_live.videos * sell_and_live.per_video;
    }
    if(sell_and_live.tickets && sell_and_live.per_ticket) {
      sellAndLiveExtraPerMonth += sell_and_live.tickets * sell_and_live.per_ticket;
    }
    if(sellAndLiveExtraPerMonth) {
      totals.sections.sell_videos_and_live_stream_tickets_at_tournaments.extraPerMonth = sellAndLiveExtraPerMonth;
    }
    if(sell_and_live.tournaments) {
      totals.sections.sell_videos_and_live_stream_tickets_at_tournaments.extraPerYear = totals.sections.sell_videos_and_live_stream_tickets_at_tournaments.extraPerMonth * sell_and_live.tournaments;
    }
    totals.totals.per_month = checkEnabledSection('raise_membership_fee', totals.sections.raise_membership_fee.extraPerMonth) +
      checkEnabledSection('raise_court_time_fee', totals.sections.raise_court_time_fee.extraPerMonth) +
      checkEnabledSection('raise_private_lesson_fee', totals.sections.raise_private_lesson_fee.extraPerMonth) +
      checkEnabledSection('raise_junior_program_fee', totals.sections.raise_junior_program_fee.extraPerYear)/12 +
      checkEnabledSection('sell_videos_and_live_stream_tickets_at_tournaments', totals.sections.sell_videos_and_live_stream_tickets_at_tournaments.extraPerYear)/12;
    totals.totals.per_year = 12 * (
        checkEnabledSection('raise_membership_fee', totals.sections.raise_membership_fee.extraPerMonth) +
        checkEnabledSection('raise_court_time_fee', totals.sections.raise_court_time_fee.extraPerMonth) +
        checkEnabledSection('raise_private_lesson_fee', totals.sections.raise_private_lesson_fee.extraPerMonth)
      ) +
      checkEnabledSection('raise_junior_program_fee', totals.sections.raise_junior_program_fee.extraPerYear) +
      checkEnabledSection('sell_videos_and_live_stream_tickets_at_tournaments', totals.sections.sell_videos_and_live_stream_tickets_at_tournaments.extraPerYear);

    return totals;
  }, [state, checkEnabledSection]);

  const renderMenuItems=()=>{
    return (
      <React.Fragment>
          <a href="https://ocamsclub.com/services" className="header-items">Services</a>
          <a href="https://ocamsclub.com/#Why-Ocams" className="header-items">Why Ocams</a>
          <a href="https://ocamsclub.com/#Why-Video" className="header-items">Why Video</a>
          <a href="https://ocamsclub.com/testimonials-categories/club-owners-managers" className="header-items">Testimonials</a>
          <a href="https://ocamsclub.com/team-careers" className="header-items">Team + Careers</a>
          <a href="https://ocamsclub.com/tips-deliver-excellent-image-quality" className="header-items">Tips</a>
          <a href="https://ocamsclub.com/contact-us" className="header-button">Contact us</a>
      </React.Fragment>
    )
  }
  const preparePdfData =()=>{
    let question1 :PdfObject ={
      name: '',
      questions: [],
      checked: false,
      totals:{
        month: '0',
        year: '0',
      }
     }
     let question2 :PdfObject={
      name: '',
      questions: [],
      checked: false,
      totals:{
        month: '0',
        year: '0',
      }
     };
     let question3 :PdfObject ={
      name: '',
      questions: [],
      checked: false,
      totals:{
        month: '0',
        year: '0',
      }
     };
     let question4 :PdfObject ={
      name: '',
      questions: [],
      checked: false,
      totals:{
        month: '0',
        year: '0',
      }
     };
     let question5 :PdfObject ={
      name: '',
      questions: [],
      checked: false,
      totals:{
        month: '0',
        year: '0',
      }
     };
      let totals:{
        per_month: string,
        per_year: string
      }={
        per_month: '0',
        per_year: '0'
      }

    totals.per_month= "$"+Math.ceil(calculations.totals.per_month).toLocaleString()
    totals.per_year="$"+Math.ceil(calculations.totals.per_year).toLocaleString()
    question1.name=sections.raise_membership_fee.name
    question1.checked = state.activeCalculatedSections.raise_membership_fee
    question1.totals.month = "$"+Math.ceil(calculations.sections.raise_membership_fee.extraPerMonth).toLocaleString()
    question1.totals.year = "$"+Math.ceil(calculations.sections.raise_membership_fee.extraPerYear).toLocaleString()
    sections.raise_membership_fee.inputs.forEach(el=>{
      let answer = (state.sections.raise_membership_fee[el.key as keyof typeof state.sections.raise_membership_fee] || 0).toString()
      let prefix
      if(el.prefix) answer = el.prefix + answer
      if(el.suffixKey) prefix = (state.sections.raise_membership_fee[el.suffixKey as keyof typeof state.sections.raise_membership_fee])
      else prefix =(el.suffix)
      question1.questions.push({question:el.name, answer:answer, prefix: prefix })
      })

    question2.name=sections.raise_court_time_fee.name
    question2.checked = state.activeCalculatedSections.raise_court_time_fee
    question2.totals.month = "$"+Math.ceil(calculations.sections.raise_court_time_fee.extraPerMonth).toLocaleString()
    question2.totals.year = "$"+Math.ceil(calculations.sections.raise_court_time_fee.extraPerYear).toLocaleString()
    sections.raise_court_time_fee.inputs.forEach(el=>{
      let answer = (state.sections.raise_court_time_fee[el.key as keyof typeof state.sections.raise_court_time_fee] || 0).toString()
      let prefix
      if(el.prefix) answer = el.prefix + answer
      if(el.suffixKey) prefix = (state.sections.raise_court_time_fee[el.suffixKey as keyof typeof state.sections.raise_court_time_fee])
      else prefix =(el.suffix)
      question2.questions.push({question:el.name, answer:answer, prefix: prefix })
      })
    
    question3.name=sections.raise_private_lesson_fee.name
    question3.checked = state.activeCalculatedSections.raise_private_lesson_fee
    question3.totals.month = "$"+Math.ceil(calculations.sections.raise_private_lesson_fee.extraPerMonth).toLocaleString()
    question3.totals.year = "$"+Math.ceil(calculations.sections.raise_private_lesson_fee.extraPerYear).toLocaleString()
    sections.raise_private_lesson_fee.inputs.forEach(el=>{
      let answer = (state.sections.raise_private_lesson_fee[el.key as keyof typeof state.sections.raise_private_lesson_fee] || 0).toString()
      let prefix
      if(el.prefix) answer = el.prefix + answer
      if(el.suffixKey) prefix = (state.sections.raise_private_lesson_fee[el.suffixKey as keyof typeof state.sections.raise_private_lesson_fee])
      else prefix =(el.suffix)
      question3.questions.push({question:el.name, answer:answer, prefix: prefix })
      })

    question4.name=sections.raise_junior_program_fee.name
    question4.checked = state.activeCalculatedSections.raise_junior_program_fee
    question4.totals.month = "$"+Math.ceil(calculations.sections.raise_junior_program_fee.extraPerMonth).toLocaleString()
    question4.totals.year ="$"+ Math.ceil(calculations.sections.raise_junior_program_fee.extraPerYear).toLocaleString()
    sections.raise_junior_program_fee.inputs.forEach(el=>{
      let answer = (state.sections.raise_junior_program_fee[el.key as keyof typeof state.sections.raise_junior_program_fee] || 0).toString()
      let prefix
      if(el.prefix) answer = el.prefix + answer
      if(el.suffixKey) prefix = (state.sections.raise_junior_program_fee[el.suffixKey as keyof typeof state.sections.raise_junior_program_fee])
      else prefix =(el.suffix)
      question4.questions.push({question:el.name, answer:answer, prefix: prefix })
    })

    question5.name=sections.sell_videos_and_live_stream_tickets_at_tournaments.name
    question5.checked = state.activeCalculatedSections.sell_videos_and_live_stream_tickets_at_tournaments
    question5.totals.month ="$"+ Math.ceil(calculations.sections.sell_videos_and_live_stream_tickets_at_tournaments.extraPerMonth).toLocaleString()
    question5.totals.year ="$"+ Math.ceil(calculations.sections.sell_videos_and_live_stream_tickets_at_tournaments.extraPerYear).toLocaleString()
    sections.sell_videos_and_live_stream_tickets_at_tournaments.inputs.forEach(el=>{
      let answer = (state.sections.sell_videos_and_live_stream_tickets_at_tournaments[el.key as keyof typeof state.sections.sell_videos_and_live_stream_tickets_at_tournaments] || 0).toString()
      let prefix
      if(el.prefix) answer = el.prefix + answer
      if(el.suffixKey) prefix = (state.sections.sell_videos_and_live_stream_tickets_at_tournaments[el.suffixKey as keyof typeof state.sections.sell_videos_and_live_stream_tickets_at_tournaments])
      else prefix =(el.suffix)
      question5.questions.push({question:el.name, answer:answer, prefix: prefix })
      })
      
    let returnObject={
      items:[question1,
      question2,
      question3,
      question4,
      question5],
      totals
    }
    return returnObject
  }

  const handleReport = async( values: any, isPrint: Boolean )=>{
    const pdfData = preparePdfData()

    let body = {
      "temp-token": "MHTVCuSQXBrMHTVCuSQXBr6XPaHpVvXxafZXPaHpVvXxafZ",
      report_to: values.email || '',
      is_print: isPrint,
      first_name: values.first_name || '',
      last_name: values.last_name || '',
      club_name: values.name || '',
      ...pdfData,
    }
    let params={
      method: "POST",
      headers:{
        accept: "application/json",
      'Content-Type': "application/json"
      },
      body: JSON.stringify(body),
    }
    let url = "https://dashboard-club.ocamsclub.com/api/v1/simulator/report-email"
    try{
        if (isPrint){
          await fetch(url, params).then(response=>response.json())
          .then(data=>{ if (data.print_url) window.open(data.print_url, "_blank")})
        } else{
          fetch(url, params)
        }
      } catch (e){
        throw e
      }
    }

  return (
    <AppWrapper>
      <HeaderLayout openDrawer={openDrawer}>
        <Header>
          <a href="https://ocamsclub.com/">
            <div
              className="logo"
              ref={logoRef}
              onMouseEnter={() => {
                logoAnimation && logoAnimation.setDirection(-1);
                logoAnimation && logoAnimation.play();
              }}
              onMouseLeave={() => {
                logoAnimation && logoAnimation.setDirection(1);
                logoAnimation && logoAnimation.play();
              }}
            />
          </a>
          {/* <div className="menu-icon-wrapper" style={{display: isMobile ? "flex" : 'none'}} onClick={()=>{
            menuAnimation && menuAnimation.setDirection(openDrawer ? -1:1);
            menuAnimation && menuAnimation.play();
            setOpenDrawer(!openDrawer)}} ref={menuRef}>
          </div>
          <div style={{display: isMobile ? "none" : "flex",  alignContent:"center", alignItems:"center", fontSize:16, lineHeight:"24px"}}>
            {renderMenuItems()}
          </div> */}
        </Header>
      </HeaderLayout>
      {/* <div className={openDrawer ? "side-drawer active":"side-drawer"}>
        <div className="drawer-items">
          {renderMenuItems()}
        </div>
      </div> */}
      <Layout>
        <Content className='content-wrapper'>
          <Row className='header-wrapper'>
            <Col span={24}>
              <CallToActionContent>
                <CallToActionTitle>{ctaTitle}</CallToActionTitle>
                <CallToActionDescription>
                  {ctaDescriptions[0]}
                </CallToActionDescription>
                <br/>
                <br/>
                <CallToActionDescription>
                  {ctaDescriptions[1]}
                </CallToActionDescription>
              </CallToActionContent>
            </Col>
          </Row>
          <Row className="body-row" >
            <Col md={{ order:1}} xs={{order:1}} className="body-row-questions">
              <Collapse
                bordered={false}
                activeKey={activeSections}
                expandIcon={({isActive}) => {
                  return isActive  ?
                  <ArrowUpIcon className='panel-arrow'/>
                  :
                  <ArrowDownIcon className='panel-arrow'/>
                }}
                expandIconPosition="right"
                onChange={(keys: string[] | string) => {
                  if(Array.isArray(keys)) {
                    handleSectionToggle(keys);
                  }
                }}
              >
                {Object.keys(sections).map((key: string, index: number) => {
                  const section = sections[key];
                  let textPerMonth = '';
                  let earnPer = 'month';
                  if(key === 'raise_junior_program_fee') {
                    textPerMonth = extraRevenuePerSession;
                    earnPer = 'session';
                  } else if(key === 'sell_videos_and_live_stream_tickets_at_tournaments') {
                    textPerMonth = extraRevenuePerTournament;
                    earnPer = 'tournament';
                  }
                  return (
                    <Panel
                      className={`${!activeCalculatedSections.includes(key) ? 'hidden-print' : ''} page-break`}
                      style={{
                        pageBreakAfter: "always",
                      }}
                      header={
                        <PanelHeader>{section.name}</PanelHeader>
                      }
                      key={key}
                    >
                      <SectionInputs
                        forceReset={state.forceReset}
                        section={key}
                        // @ts-ignore
                        values={state.sections[key]}
                        onChange={values => handleChange(key, values)}
                        inputs={section.inputs}
                      />
                      <SectionCalculations
                        textPerMonth={textPerMonth}
                        forceReset={state.forceReset}
                        impacts={section.impacts}
                        // @ts-ignore
                        extraPerMonth={calculations.sections[key].extraPerMonth}
                        // @ts-ignore
                        extraPerYear={calculations.sections[key].extraPerYear}
                        // @ts-ignore
                        firstLabel={earnPer}
                      />
                    </Panel>
                  );
                })}
              </Collapse>

            </Col>
            <Summary md={{ order:2}} xs={{order:2}}>
              <FocusCard className="summary-box">
                <SectionToggle
                  activeSections={activeCalculatedSections}
                  onChange={handleCalculatedSectionToggle}
                />
                <FinalCalculation
                  monthly={calculations.totals.per_month}
                  yearly={calculations.totals.per_year}
                />
              </FocusCard>
              <Row align="middle" >
                <Col className="print-button" span={24}>
                  <ActionButtonBordered
                    block
                    icon={<><PrintIconO className="icon" /><PrintIconHover className="icon-hover" /></>}
                    onClick={() => {
                      // window.print();
                      handleReport({}, true);
                      handleTrack();
                    }}
                  >
                    Print
                  </ActionButtonBordered>
                </Col>
                <Col className="email-button" span={24}>
                  <ActionButton
                    block
                    icon={<><EmailIcon className="icon"/><EmailIconHover className="icon-hover"/></>}
                    onClick={() => {
                      setEmailModalStatus(true);
                    }}
                  >
                    Email Report
                  </ActionButton>
                </Col>
              </Row>
            </Summary>
          </Row>
        </Content>
      </Layout>
      <Footer>
      © {new Date().getUTCFullYear()} SmartShot Technology Inc New York, NY
      </Footer>
      <EmailModal
        visible={emailModalStatus}
        onOk={() => {
          setEmailModalStatus(false);
          setConfirmationModalStatus(true);
        }}
        onCancel={() => {
          setEmailModalStatus(false);
        }}
        onSubmit={handleReport}
        onTrack={handleTrack}
      />
      <ConfirmationModal
        visible={confirmationModalStatus}
        onCancel={() => {
          setConfirmationModalStatus(false);
        }}
      />
    </AppWrapper>
  );
};
