import styled from "styled-components";
import {Menu} from "antd";

export const TimeDropdownMenu = styled(Menu.Item)`
  font-size: 14px; 
  text-align:center;
  font-family: 'Inter Regular', sans-serif;
  position:relative;
  left:0;
  color: rgba(18,18,18,0.6);
  padding: 5px 28px 5px 5px;
  text-align: right;
  &.ant-dropdown-menu-item-selected {
    background-color: #DBEDFF;
    color: #3399FF;
  }
  // &:hover{ 
  //   background: #DBEDFF
  // }
  // &:hover {
  //   background-color: #ffffff;
  //   color: #000000;
  // }
`;
