import styled from "styled-components";
import Title from "antd/es/typography/Title";

export const CallToActionTitle = styled(Title)`
  font-family: "Inter ExtraBold" !important;
  font-weight: inherit !important;
  font-size: 64px !important;
  line-height: 77px !important;
  color: #000000;
  margin-bottom: 41px !important;
  -webkit-font-smoothing: antialiased;
  
  @media (max-width:991px){
    font-size: 48px !important;
    line-height: 58px !important;
    margin-bottom:40px  !important;
  }

  @media (max-width:453px){
    font-size: 36px !important;
    line-height: 44px !important;
    margin-bottom: 23px  !important;
  }
`;
