import styled from "styled-components";
import { Button } from "antd";

export const ActionButtonBordered = styled(Button)`
  width: 200px;
  height: 60px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  background: none;
  border: none;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  &:hover,
  :focus,
  :active {
    background: none;
  }
  @media (max-width: 991px) {
    font-size: 16px;
  }
`;
