import styled from "styled-components";

export const InputLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height:19px;
  color: #000000 !important;
  @media (max-width: 453px){
    font-size: 14px !important;
    line-height:24px;
  }
`;