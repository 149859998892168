import styled from "styled-components";

export const AppWrapper = styled.div`
  * {
 font-synthesis: none !important;
 }
  .panel-arrow {
    margin-top: 0;
    margin-right: 0;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    right: 0px !important;
  }
  .input-error{
    font-style: normal;
    font-weight: 400;
    font-family: "Inter Regular";
    font-size: 14px;
    margin-top: 8px;
    line-height: 17px;
    color: red;
  }

  .ant-form-item-explain-error{
    display: none !important;
  }

  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
    background: none !important;
  }
  .label-container{
    flex-direction: column;
    align-items: flex-start
  }
  .panel-calculation-section {
    margin-top: 30px;
    width: 498px;
    height: 207px;
    background: #dbedff;
  }

  .content-wrapper {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  * {
    font-family: "Inter Regular", sans-serif;
  }

  *::selection {
    color: #fff !important;
    background: #333333 !important;
  }
  .ant-dropdown-trigger {
    color: rgba(18, 18, 18, 0.6);
  }
  .ant-typography,
  *.ant-typography {
    color: #121212;
  }

  .ant-checkbox-inner {
    background-color: transparent;
    border-color: #333333 !important;
    border-radius: 5px;
    height: 14px;
    width: 14px;
  }

  .ant-checkbox-inner::after {
    top: 45%;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #333333 !important;
  }

  .ant-checkbox {
    margin-top: 6px;
    vertical-align: top;
  }

  .ant-checkbox + span {
    padding: 0px;
    display: inline-block;
    margin-left: 12px;
    width: 80%;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper {
    border: 0px;
    box-shadow: none !important;
  }

  .section-inputs {
    margin-top: 14px;
    width: 240px;
    display: flex;
    align-items: center;
    background: rgba(18, 18, 18, 0.05);
    justify-content: space-between;
    height: 50px;
    padding: 0 18px;
    border-radius: 5px;
    input {
      border: none !important;
      background: transparent;
      box-shadow: none !important;
    }
    &:focus-within,
    :hover {
      box-shadow: 0px 2px 6px rgba(18, 18, 18, 0.7);
    }
    .ant-dropdown-trigger {
      font-size: 16px;
      span {
        width: 55px;
        display: inline-block;
        text-align: right;
      }
      @media (max-width: 453px) {
        font-size: 12px;
      }
    }
    @media (max-width: 453px) {
      margin-top: 11px;
    }
  }

  .ant-input {
    width: 60px;
    min-width: 60px;
    height: 22px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 15px;
    padding: 5px;
    text-align: center;
  }

  .ant-input::placeholder {
    color: #000000;
  }

  .ant-input-suffix,
  .ant-input-prefix {
    background: rgba(18, 18, 18, 0.05) !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(20, 20, 20, 0.6);
  }

  .ant-input-suffix {
    width: 90px;
  }

  .ant-input-affix-wrapper {
    align-items: center;
  }

  .ant-form-item-control-input-content {
    display: flex;
    & .ant-dropdown-trigger {
      font-size: 16px;
      color: rgb(0, 0, 0, 0.5);
      text-decoration: none !important;
      padding-left: 5px;
      width: fit-content;
      margin-top: 2px;
      margin-bottom: 20px;
    }
    & > input {
      height: 30px;
      text-align: start;
      padding: 5px;
      font-size: 16px;
      line-height: 24px;
    }
    & .ocams-prefix-offset {
      border: none;
      padding: 0;
      height: 30px;
      font-size: 14px;

      & input {
        height: 30px;
        text-align: start;
        padding: 5px;
        font-size: 16px;
        line-height: 24px;
        transition: none !important;
        background: rgba(18, 18, 18, 0.05) !important;
        @media (max-width: 453px) {
          font-size: 14px;
        }
      }
      & .ant-input-suffix,
      .ant-input-prefix {
        font-size: 12px;
        transition: none !important;
        color: rgb(0, 0, 0, 0.5);
        text-decoration: none !important;
      }
      & .ant-input-prefix {
        margin: 0;
        height: 100%;
        padding-left: 5px;
      }
      .ant-input-suffix {
        padding-left: 5px;
        width: fit-content;
      }
    }
  }
  .with-prefix {
    & input {
      width: 89px;
    }
    &:focus-within,
    :hover {
      & input,
      .ant-input-prefix {
        transition: none !important;
      }
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .header-items {
    color: #121212;
    font-size: 14px;
    margin-right: 15px;
    font-family: Inter Medium;
    font-weight: 500;
    transition: all 500ms ease;
    :hover {
      color: #39f;
    }
  }

  .header-button {
    border: none;
    border-radius: 50px;
    background: #3399ff;
    color: white;
    height: 55px;
    padding: 18px 30px;
    line-height: 100%;
    transition: all 500ms ease;
    :hover {
      background: #121212;
    }
  }

  .footer-text-btn,
  .footer-call-btn {
    padding: 18px 30px;
    border-radius: 50px;
    font-size: 16px;
    line-height: 1.21;
  }

  .footer-text-btn {
    background: transparent;
    color: white;
    border: 1px solid white;
  }

  .footer-call-btn {
    background: white;
    color: #121212;
    border: none;
    margin-bottom: 20px;
  }
  @media (max-width: 991px) {
    .footer-call-btn,
    .footer-text-btn {
      padding-right: 50px;
      padding-left: 50px;
    }
  }
  .summary-box {
    padding: 0px;
    margin-bottom: 30px;
  }
  @media (max-width: 881px) {
    .summary-box {
      max-width: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: unset !important;
    }
  }
  @media (max-width: 479px) {
    .footer-call-btn,
    .footer-text-btn {
      padding: 15px 35px;
    }
  }
  @media print {
    .hidden-print {
      display: none !important;
    }

    .page-break {
      page-break-after: always;
    }
  }

  .section-calculation {
    padding: 33px 34px 0px;
    border-radius: 15px;
    margin-top: 34px;
    margin-bottom: 52px;
    max-width: 498px;
    width: 100%;
    height: 207px;
    background: #dbedff;
    div {
      display: flex;
      justify-content: start;
      align-content: center;
      height: 57px;
      span {
        font-size: 36px;
        line-height: 54px;
        color: #3399ff;
      }
      span:last-child {
        font-size: 14px;
        color: #121212;
        padding-left: 10px;
      }
      @media (max-width: 575px) {
        span {
          margin-top: 10px;
          font-size: 28px;
        }
        span:last-child {
          font-size: 14px;
        }
      }
    }
    .section-per-month{
      margin-top:10px
    }
    .section-per-year{
      margin-top: 12px;
    }
    @media (max-width: 1039px) {
      margin-bottom: 38px;
      max-width: 490px;
      width: 100%;
      height: 205px;
    }
    @media (max-width: 992px) {
      max-width: 475px;
      width: 100%;
    }
    @media (max-width: 991px) {
      max-width: 362px;
      width: 100%;
      height: 180px;
      margin-bottom: 41px;
      div {
        height: 37px;
        span {
          font-size: 28px;
          line-height: 42px;
        }
      }
    }
    @media (max-width: 453px) {
      max-width: 373px;
      width: 100%;
      height: 174px;
      margin-bottom: 38px;
      div {
        span {
          font-size: 24px;
          line-height: 36px;
        }
      }
      .section-per-year{
        margin-top: 6px
      }
    }

    @media (max-width: 320px) {
      max-width: 240px;
      width: 100%;
      height: 174px;
    }
  }
  .header-wrapper {
    max-width: 920px;
    @media (max-width: 991px) {
      max-width: 738px;
    }
  }
  .body-row {
    width: 100%;
    margin-bottom: 179px;
    margin-top: 87px;
    justify-content: center;
    .body-row-questions {
      max-width: 582px;
      width: 100%;
      margin-right: 30px;
    }
    @media (max-width: 1039px) {
      .body-row-questions {
        max- width: 576px;
        width: 100%;
        margin-right: 15px;
      }
    }
    @media (max-width: 1020px) {
      .body-row-questions {
        max-width: 559px;
        width: 100%;
      }
    }

    @media (max-width: 991px) {
      .body-row-questions {
        max-width: 434px;
        width: 100%;
      }
      margin-bottom: 120px;
    }
    @media (max-width: 767px) {
      .body-row-questions {
        margin-bottom: 40px;
        margin-right: 0px;
      }
    }
    @media (max-width: 320px) {
      .body-row-questions {
        max-width: 300px;
        width: 100%;
      }
    }
  }
  @media (max-width: 991px) {
    .body-row {
      margin-top: 54px;
    }
  }

  // @media (max-width: 575px) {
  //   .body-row {
  //     margin-top: 30px;
  //   }
  //   .ant-layout-header {
  //     img:first-child {
  //     }
  //   }
  //   .ant-collapse {
  //     .ant-collapse-item:last-child {
  //       height: fit-content !important;
  //     }
  //   }
  //   .ant-input-affix-wrapper {
  //   }
  //   .ant-input-suffix,
  //   .ant-dropdown-trigger {
  //     width: 100%;
  //   }
  //   .ant-collapse-content-active {
  //     padding-right: 20px;
  //   }

  //   .section-calculation .right {
  //     margin-top: 20px;
  //   }
  // }
  .print-button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    border: 1px solid white;
    border-radius: 50px;
    &:hover,
    :focus {
      background: white;
      & span {
        color: #3399ff;
      }
      & img {
        filter: invert(1);
      }
      & path {
        fill: #3399ff;
      }
    }
    @media (max-width: 991px){
      margin-top: 9px;
    }
    @media (max-width: 767px){
      margin-top: 19px;
    }
    @media (max-width: 453px) {
      margin-top: 9px;
    }
  }
  .email-button {
    display: flex;
    justify-content: center;
    background: white;
    margin-top: 19px;
    border-radius: 50px !important;
    &:hover,
    :focus,
    :active {
      background: black !important;
      & button {
        background: black !important;
        transition: none;
      }
      & span {
        color: white;
      }
      & img {
        filter: invert(1);
      }
    }
  }

  .email-button,
  .print-button {
    flex:none;
    .icon,
    .icon-hover {
      margin-right: 10px;
    }
    .icon-hover {
      display: none;
    }
    &:hover{
      .icon{
        display: none;
      }
      .icon-hover{
        display: block;
      }
    }
    svg {
      width: 22px;
      height: 22px;
    }
    @media (max-width: 991px) {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  .question-wrapper {
    margin-top: 27px;
    &:first-child {
      margin-top: 0px;
    }
  }
  .bluebox-checkboxes {
    div {
      line-height: initial;
    }
    flex-flow: initial;
    line-height: unset;
    margin-bottom: 13px;
    width: 100%;
    &:last-child {
      margin-bottom: 0px;
    }
    @media (max-width: 900px) and (min-width: 768px){
      margin-bottom: 16px;
      &:nth-last-child(2){
        margin-bottom: 13px;
      }
      &:last-child {
        div{
          line-height: 24px;
        }
      }
    }
    @media (max-width: 453px) {
      margin-bottom: 16px;
    }
  }

  .email-button,
  .print-button, 
  .bluebox-finalcalculation {
    width: 100%;
    max-width: 292px;
    @media (max-width: 1039px) and (min-width: 993px) {
      max-width: 288px
    }
    @media (max-width: 992px){
      max-width: 277px;
    }
    @media (max-width: 991px) and (min-width: 768px) {
      max-width: 229px;
    }
    @media (max-width: 767px) {
      max-width: 292px;
    }
    @media (max-width: 453px) {
      max-width: 358px;
    }
    @media (max-width: 320px) {
      max-width: 229px;
    }

  }
  .bluebox-finalcalculation {
    padding-right: 0px !important;
    padding: 29px 36px;
    height: 171px;
    background: #ffffff;
    border: 3px solid #000000;
    border-radius: 15px;
    div {
      justify-content: flex-start;
      padding: 0;
    }
    span {
      color: #121212 !important;
    }
    @media (max-width: 991px){
      height: 164px;
      padding: 38px 25px;
    }
    @media (max-width: 767px){
      height: 171px;
      padding: 29px 0px 29px 36px;
    }
    @media (max-width: 453px) {
      height: 164px;
      padding: 38px 25px;
    }
  }

  .ant-layout-content {
    align-self: center;
    @media (max-width: 1070px) {
      align-self: initial;
    }
  }

  .drawer-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    top: 0;
    overflow: visible;
    background-color: #fff;
    a {
      margin-top: 20px;
      padding: 20px 0;
      font-size: 18px;
      text-align: center;
      margin-right: 15px;
      line-height: 1.21;
      width: 100%;
      &:last-child {
        width: unset;
        padding: 18px 50px;
        margin-right: 0px;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .side-drawer {
    background: white;
    position: fixed;
    top: 90px;
    height: 100%;
    left: 0;
    width: 100%;
    overflow: visible;
    padding: 0 20px;
    z-index: 1;
    transform: translateY(-100%);
    transition: transform 0.4s ease-out;
  }

  .side-drawer.active {
    transform: translateY(0px);
    display: block;
    .drawer-items {
      // transform: translateY(90px);
    }
  }
  @media (max-width: 479px) {
    .side-drawer {
      top: 98px;
      padding: 0 40px;
    }
    .drawer-items {
      a {
        margin-right: 0px;
        margin-top: 0;
        text-align: left;
        font-size: 18px;
        line-hieght: 1.2;
        &:last-child {
          display: flex;
          justify-content: center;
          height: 49px;
          width: 100%;
          margin-top: 18px;
          padding: 0;
          align-items: center;
        }
      }
    }
  }
`;
