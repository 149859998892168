import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const SectionToggleTitle = styled(Text)`
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  font-family: "Inter SemiBold";
  color:white !important;
  
  @media (max-width: 991px){
    font-size: 28px;
    line-height: 34px;
  }

  @media (max-width: 767px){
    font-size: 36px;
    line-height: 44px;
  }

  @media (max-width: 453px){
    font-size: 28px;
    line-height: 34px;
  }
`;
